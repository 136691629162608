.fc-col-header {
  background-color: #feda3b;
  color: #000;
}

.fc .fc-col-header-cell-cushion {
  font-size: "12px";
  padding: 10px 4px;
  border-radius: "8px";
}

.fc .fc-scrollgrid-section {
  background-color: #fff;
  color: #8a9ead;
}
:root {
  --fc-border-color: #dce2e6;
  --fc-daygrid-event-dot-width: 5px;
}

.fc .fc-daygrid-event {
  cursor: pointer;
}

.fc .fc-daygrid-day-top {
  height: 20px;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  height: 20px;
  min-height: 20px;
}
